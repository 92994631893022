.BasicModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  justify-content: space-between;
  height: 100%;
}

.title {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.message {
  width: 80%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.oneButton {
  justify-content: center;
}

.button {
  width: 45%;
}

.buttonText {
  margin: 10px;
}
