@use "src/common/styles/" as *;

.RequestDetails {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.title {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.requestDetailTitle {
  font-size: medium;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: $light-gray;
}

.requestDetailValue {
  font-size: medium;
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: $semi-bold;
}

@media (width >= 850px) {
  .RequestDetails {
    width: 100%;
  }
}