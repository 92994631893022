@use "src/common/styles/" as *;

.MessagePanel {
  height: 60vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
}

.messageView {
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  width: 65%;
  font-size: medium;
  line-height: 1.25;
  overflow-x: clip;
}

.timestamp {
  font-size: small;
  font-style: italic;
}

.customerMessage {
  align-self: flex-start;
  background-color: $lighter-gray;

  .timestamp {
    color: $light-gray;
  }
}

.restaurantMessage {
  align-self: flex-end;
  background-color: $blue;
  color: $white;

  .timestamp {
    color: $lighter-gray;
  }
}

.messageInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid $light-gray;
  align-items: flex-end;
}

.messageInput {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $light-gray;
  font-family: inherit;
  font-size: smaller;
  resize: none;
  overflow: visible; 
  height: 60px;
  box-sizing: border-box;
  align-self: stretch;
}

.messageInput:focus {
  outline: none;
  box-shadow: 0 0 5px $blue;
}

.messageButtons {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.attachmentButton {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: $white;
  color: $blue;
  cursor: pointer;
  font-size: medium;
}

.attachmentButton:hover {
  border-radius: 5px;
  background-color: $lighter-gray;
}

.sendButton {
  margin-left: 5px;
  padding: 5px 15px;
}

@media (width >= 875px) {
  .MessagePanel {
    height: 68vh;
  }

  .message {
    width: 50%;
  }
}