@use "src/common/styles/" as *;

.Home {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
}

.dateRangeExportRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
}

.statCardsContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.statCard {
  width: 48%;
  margin-bottom: 25px;
}

.exportButton {
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}

.exportCircle {
  background-color: $blue;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  border: 2px solid $white;
  box-sizing: border-box;
}

.exportIcon {
  color: $white;
  width: 15px;
  height: 15px;
}

@media (width >= 875px) {
  .Home {
    width: 100%;
  }

  .statCardsContainer {
    width: 92.5%;
  }

  .statCard {
    width: 31%;
  }

  .dateRangeExportRow {
    width: 92.5%;
    flex-direction: row;
  }
}
