@use "src/common/styles/" as *;

.Settings {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
}

.settingsItemsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

@media (width >= 875px) {
  .Settings {
    width: 100%;
  }

  .settingsItemsContainer {
    width: 95%;
  }
}
