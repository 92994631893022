@use "src/common/styles/" as *;

.CustomerDetails {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.title {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.customerName {
  margin: 0;
  padding: 0;
  font-size: small;
  font-weight: $semi-bold;
  margin-top: 20px;
}

.customerSubInfo {
  font-size: small;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: $light-gray;
}

.actionsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.actionIcon {
  color: $black;
  width: 20px;
  height: 20px;
}

.actionContainer {
  cursor: pointer;
  width: 28%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  transition: all 0.3s;

  &:hover {
    background-color: $light-gray;

    .actionIcon {
      color: $white;
    }
  }
}

.noCustomer {
  width: 48%;
}

@media (width >= 1200px) {
  .CustomerDetails {
    width: 100%;
  }
}
