@use "src/common/styles/" as *;

.CustomerDetails {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.title {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.customerDetailTitle {
  font-size: medium;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: $light-gray;
  white-space: nowrap;
  overflow: hidden;

}

.customerDetailValue {
  font-size: medium;
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: $semi-bold;
}

.emailLink {
  font-size: medium;
  color: $blue;
  text-decoration: underline;
  margin: 0;
  padding: 0;
}

@media (width >= 1200px) {
  .CustomerDetails {
    width: 100%;
  }
}
