$default-font-family: "Montserrat";
$regular: 400;
$semi-bold: 500;
$bold: 600;
$extra-bold: 700;

:export {
  default-font-family: $default-font-family;
  regular: $regular;
  semi-bold: $semi-bold;
  bold: $bold;
  extra-bold: $extra-bold;
}
