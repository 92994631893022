/* Colors used throughout the website */
$blue: #2266a6;
$light-gray: #777;
$lighter-gray: #d9d9d9;
$very-light-gray: #ececec;
$light-green: #1dc31a;
$dark-green: #2ca622;
$red: #a62222;
$white: #fff;
$black: #000;

:export {
  blue: $blue;
  light-gray: $light-gray;
  very-light-gray: $very-light-gray;
  light-green: $light-green;
  dark-green: $dark-green;
  red: $red;
  white: $white;
  black: $black;
}
