@use "src/common/styles/" as *;

.SocialMediaLinksContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 50px;
}

.SocialMediaLinks {
  display: flex;
  width: 95%;
  background-color: $white;
  flex-direction: column;
  align-self: center;
  margin-bottom: 30px;
}

.socialMediaInput {
  width: 100%;
}

.bottomSaveButton {
  width: 100%;
  height: 80px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  box-shadow: 0 6px 20px 10px rgb(0 0 0 / 19%) !important;
  cursor: pointer;
}

.saveText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: large;
  font-weight: $bold;
}

.socialMediaLabelContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
}

.socialMediaLabel {
  margin: 0;
  padding: 0;
  font-size: medium;
  font-weight: $semi-bold;
}

.socialMediaImage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

@media (width >= 875px) {
  .SocialMediaLinks {
    width: 100%;
  }

  .socialMediaInput {
    width: 48%;
  }

  .socialMediaLabelContainer {
    width: 48%;
  }

  .bottomSaveButton {
    width: calc(100% - 225px);
    margin-left: -50px;
  }

  .socialMediaLabel {
    font-size: larger;
  }
}
