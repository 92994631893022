@use "src/common/styles/" as *;

.RestaurantContactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 40px 0;
}

.learnMore {
  color: $blue;
  font-weight: $bold;
}

.contactUsContainer {
  width: 85%;
  background-color: $white;
  border-radius: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.brandContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.logo {
  width: 60px;
  height: 60px;
}

.brandText {
  margin-left: 5px;
  color: $blue;
  font-style: italic;
}

.title {
  margin: 0;
}

.subtitle {
  margin-top: 10px;
  color: $light-gray;
  font-weight: $semi-bold;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: small;
}

.selectableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.selectableText {
  margin: 0;
  margin-left: 10px;
  color: $black;
  font-weight: $semi-bold;
  font-size: small;
}

.additionalInput {
  margin-top: 10px;
  margin-bottom: 10px;
}

.emailInput {
  margin-bottom: 30px;
}

.phoneInput {
  margin-bottom: 30px;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 20px;
}

.loadingContainer {
  align-self: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.errorBox {
  width: calc(100% - 1px);
  margin-bottom: 25px;
  background-color: #ffcccb;
  border-radius: 10px;
  border: $red 1px solid;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.errorBoxText {
  color: $red;
  font-weight: $bold;
  margin: 0;
  margin-left: 10px;
}

.errorIcon {
  color: $red;
}

@media (width >= 875px) {
  .contactUsContainer {
    width: 45%;
  }

  .subtitle {
    font-size: medium;
  }

  .selectableText {
    font-size: medium;
  }
}
