@use "src/common/styles/" as *;

.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  width: 100%;
  height: 100vh;
}

.loginContainer {
  width: 85%;
  background-color: $white;
  border-radius: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.brandContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.logo {
  width: 60px;
  height: 60px;
}

.brandText {
  margin-left: 5px;
  color: $blue;
  font-style: italic;
}

.loginText {
  margin: 0;
}

.loginSubtext {
  margin-top: 10px;
  color: $light-gray;
  font-weight: $semi-bold;
  margin-bottom: 30px;
}

.input {
  margin-bottom: 20px;
}

.loginButton {
  margin-top: 30px;
  margin-bottom: 20px;
}

.loadingContainer {
  align-self: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.errorBox {
  width: calc(100% - 1px);
  margin-bottom: 25px;
  background-color: #ffcccb;
  border-radius: 10px;
  border: $red 1px solid;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.errorBoxText {
  color: $red;
  font-weight: $bold;
  margin: 0;
  margin-left: 10px;
}

.errorIcon {
  color: $red;
}

@media (width >= 875px) {
  .loginContainer {
    width: 45%;
  }
}
