@use "src/common/styles/" as *;

.MoneyTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $very-light-gray;
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 15px;
  box-sizing: border-box;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;
  width: 100%;
  align-items: center;
  border-bottom: $lighter-gray 1px solid;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.tableText {
  margin: 0;
  font-weight: $bold;
  font-size: small;
}

.tableTextBig {
  margin: 0;
  font-weight: $bold;
  font-size: medium;
}

@media (width >= 875px) {
  .MoneyTable {
    width: 92.5%;
    padding: 25px;
  }

  .tableText {
    font-size: medium;
  }

  .tableTextBig {
    font-size: large;
  }
}
