@use "src/common/styles/" as *;

.PageTitle {
  margin-top: 35px;
}

.title {
  color: $black;
  margin: 0;
}

.subtitle {
  color: $light-gray;
  margin: 0;
  margin-top: 15px;
  font-weight: $semi-bold;
  font-size: medium;
}

@media (width >= 875px) {
  .subtitle {
    font-size: large;
  }
}
