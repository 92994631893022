@use "src/common/styles/" as *;

.createItemModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
    width: 95%;
    max-width: 500px;
    max-height: 85vh;
    z-index: 11;
}

.scrollableContent {
    flex: 1;
    overflow-y: auto;
    max-height: 70vh;
    padding-right: 10px;
    width: 100%;
}

.modalHeader {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.photoChooserContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.formGroup {
    width: 100%;
    margin-bottom: 15px;
}

.label {
    font-size: large;
    font-weight: $bold;
    padding-bottom: 10px;
    width: 90%;
}

.textInput {
    width: 100%;
}

.priceInput {
    width: 150px;
}

.dropdown {
    width: 100%;
    border-radius: 25px;
    margin-top: 10px;
}

.modalStyles {
    background-color: none;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.checkboxLabel {
    font-size: large;
    font-weight: $semi-bold;
}

.errorMessage {
    color: $red;
    font-size: large;
    margin-bottom: 10px;
    text-align: center;
}

.buttonsContainer {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid $lighter-gray;
}

@media (width > 700px) {
    .createItemModal {
        width: 100%;
        padding: 15px;
        border-radius: 10px;
    }

    .buttonsContainer {
        flex-direction: row;
    }

    .buttonPrimary,
    .buttonSecondary {
        width: 200px;
    }

    .checkboxLabel {
        font-size: medium;
    }

}