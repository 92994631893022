@use "src/common/styles/" as *;

.OrderItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: $lighter-gray 1px solid;
  padding: 15px 0;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.refunded {
  opacity: 0.5;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.strikethroughRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.beforeDealPrice {
  margin-right: 10px !important;
  text-decoration-line: line-through;
}

.mainText {
  margin: 0;
  padding: 0;
  color: $black;
}

.quantityText {
  margin: 0;
  padding: 0;
  font-weight: $extra-bold;
}

.subText {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  font-size: small;
  color: $light-gray;
  margin-top: 5px;
}

.setTextWidth {
  width: 70%;
}

.dealRow {
  margin-top: 10px;
  align-self: flex-start;
}
