@use "src/common/styles/" as *;

.DateRangePicker {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  position: relative;
  height: 42px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
}

.dropdown {
  width: 50%;
}

.dropdownControl {
  border-radius: 10px 0 0 10px !important;
  font-size: small !important;
  font-weight: $semi-bold !important;
  border: 1px solid $lighter-gray;
  cursor: pointer !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.dropdownMenu {
  margin-top: 5px !important;
  font-size: small !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
  border-radius: 5px;
  animation: scale-up 0.1s ease-out forwards;

  > div {
    color: $black !important;
  }
}

.dateRange {
  min-width: 50%;
  max-width: fit-content;
  border-radius: 0 10px 10px 0;
  background-color: $white;
  height: 100%;
  border: 1px solid $lighter-gray;
  border-left: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 15px;
}

.calendarIcon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.datesText {
  color: $black;
  font-size: small;
  font-weight: $semi-bold;
  margin: 0;
  padding: 0;
}

.rangePickerContainer {
  position: absolute;
  width: 100%;
  animation: scale-up 0.1s ease-out forwards;
  z-index: 100;
  top: 100%;
}

.rangePicker {
  width: 100%;
  margin-top: 5px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
  border-radius: 5px;
}

@keyframes scale-up {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media (width >= 875px) {
  .DateRangePicker {
    align-self: flex-start;
    width: 400px;
  }
}
