@use "src/common/styles/" as *;

.TextInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin: 0;
  font-size: medium;
  margin-bottom: 8px;
  font-weight: $bold;
}

.errorLabel {
  color: $red;
}

.input {
  width: 100%;
  border-radius: 10px;
  border: $light-gray 1px solid;
  padding: 10px;
  box-sizing: border-box;
  font-size: medium;
  outline: none;

  &:focus {
    border: $blue 1px solid;
  }
}

.textarea {
  resize: none;
  height: 100px;
}

.errorMessage {
  margin: 0;
  color: $red;
  font-weight: normal;
  font-size: medium;
  margin-top: 8px;
}

.errorInput {
  border: $red 2px solid;
}

.inputPrefixContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.textPrefix {
  margin: 0;
  padding: 0;
  margin-right: 5px;
  font-size: larger;
  font-weight: $semi-bold;
}

@media (width >= 875px) {
  .label {
    font-size: larger;
  }

  .errorMessage {
    font-size: larger;
  }
}
