@use "src/common/styles/" as *;

.CateringRequest {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  height: 100%;
}

.contentContainer {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.actionButtons {
  display: flex;
  justify-content: space-between; 
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.declineButton {
  padding: 10px 20px; 
  width: 35%;
}

.acceptButton {
  padding: 10px 20px; 
  width: 60%;
}


@media (width >= 875px) {
  .CateringRequest {
    width: 100%;
  }

  .loadingContainer {
    width: 95%;
  }

  .contentContainer {
    width: 95%;
  }

  .desktopLeft {
    width: 66%;
  }

  .desktopRight {
    width: 31%;
  }

  .requestDetails {
    margin-right: 25px;
  }

  .headerLeft {
    flex-direction: row;
  }
}

@media (width >= 1200px) {
  .contentContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}