@use "src/common/styles/" as *;

.ExportModal {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 30px;
}

.title {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}

.exportTypeDescription {
  margin-left: 10px;
  font-weight: $semi-bold;
  color: $light-gray;
  transition: color 0.3s;
  font-size: small;
}

.type {
  margin-bottom: 30px;

  &:hover {
    .exportTypeDescription {
      color: $black;
    }
  }
}

.typeTitle {
  margin: 0;
  padding: 0;
}

.typeSelection {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.exportButton {
  width: 100%;
  align-self: center;
}

.exportingLoading {
  align-self: center;
}

@media (width >= 875px) {
  .exportTypeDescription {
    font-size: medium;
  }

  .exportButton {
    width: fit-content;
    align-self: flex-end;
  }

  .exportingLoading {
    align-self: flex-end;
  }
}
