@use "src/common/styles/" as *;

.BasicDetails {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.title {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.basicDetailTitle {
  font-size: small;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: $light-gray;
}

.deliveryOrder {
  margin: 0;
  padding: 0;
  font-size: small;
  color: $light-gray;
}

.clickableTrackingLink {
  margin-left: 5px;
  cursor: pointer;
}

.trackingLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.trackingLinkText {
  margin: 0;
  padding: 0;
  color: $blue;
  font-size: small;
  text-decoration-line: underline;
  font-weight: $semi-bold;
}

.basicDetailValue {
  font-size: small;
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: $semi-bold;
}

@media (width >= 1200px) {
  .BasicDetails {
    width: 100%;
  }
}
