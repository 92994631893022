@use "src/common/styles/" as *;

.SideMenu {
  width: 225px;
  height: calc(100vh - 80px);
  background-color: $very-light-gray;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 45px;
  margin-right: 50px;
  position: sticky;
  top: 80px;
}

.SideMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 10px 0 10px 20px;
  text-decoration: none;
}

.SideMenuItemActive {
  background-color: #2266a633;
}

.icon {
  color: $light-gray;
  width: 22.5px;
  height: 22.5px;
  margin-right: 20px;
}

.iconActive {
  color: $blue;
}

.title {
  color: $black;
  margin: 0;
}

.titleActive {
  color: $blue;
}

.signOut {
  position: absolute;
  bottom: 0;
}

@media (width < 875px) {
  .SideMenu {
    display: none;
  }
}
