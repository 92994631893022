@use "src/common/styles/" as *;

.Catering {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
}

.loadingContainer {
  width: 100%;
}

.topRow {
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.entityTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.entityText {
  font-size: medium;
  color: $black;
  cursor: pointer;
}

.entityTextSelected {
  text-decoration: underline;
  color: $blue;
}

.searchContainer {
  width: 100%;
  align-self: center;
  border: $lighter-gray 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 7.5px 10px;
  box-sizing: border-box;
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.searchInput {
  border: none;
  width: calc(100% - 40px);
  outline: none;
}

.headerRow {
  margin-top: 20px;
  cursor: unset !important;

  &:hover {
    background-color: $white !important;
  }
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: $lighter-gray 1px solid;
  width: 100%;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lighter-gray;
  }
}

.rowSubText {
  padding: 0;
  margin: 0;
  font-weight: $semi-bold;
  font-size: small;
  color: $light-gray;
  margin-top: 5px;
}

.requestNumberColumn {
  width: 55%;
}

.dateColumn {
  display: none;
  width: 25%;
}

.nameColumn {
  display: none;
  width: 20%;
}

.companyColumn {
  display: none;
  width: 20%;
}

.budgetColumn {
  display: none;
  width: 15%;
}

.statusColumn {
  width: 30%;
}

.headerText {
  padding: 0;
  margin: 0;
}

.rowText {
  padding: 0;
  margin: 0;
  font-weight: $semi-bold;
  font-size: small;
}

.buttonsContainer {
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.statusIndicator {
  width: 100%;
}

.noRequestsMessage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.noRequestsText {
  font-size: large; 
}

@media (width >= 875px) {
  .Catering {
    width: 100%;
  }

  .searchContainer {
    width: 95%;
    align-self: flex-start;
  }

  .row {
    width: 95%;
  }

  .loadingContainer {
    width: 95%;
  }

  .topRow {
    justify-content: space-between;
  }

  .requestNumberColumn {
    width: 12%;
  }

  .dateColumn {
    display: block;
    width: 22%;
  }

  .nameColumn {
    display: block;
    width: 15%;
  }

  .companyColumn {
    display: block;
    width: 15%;
  }

  .budgetColumn {
    display: block;
    width: 20%;
  }

  .statusColumn {
    width: 10%;
  }

  .buttonsContainer {
    align-self: flex-end;
    width: 30%;
    margin-right: 5%;
  }
}