@use "src/common/styles/" as *;

.buttonText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: medium;
  font-weight: $bold;
}

.Button {
  background-color: $blue;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: $white;
  border: 2px solid $blue;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  transition:
    background-color 0.3s,
    color 0.3s;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $white;

    .buttonText {
      color: $blue;
    }
  }
}

.secondary {
  background-color: $white;
  color: $blue;

  &:hover {
    background-color: $blue;

    .buttonText {
      color: $white;
    }
  }
}

.secondaryError {
  background-color: $white;
  color: $red;
  border: 2px solid $red;

  &:disabled > * {
    color: $white;
  }

  &:hover {
    background-color: $red;

    .buttonText {
      color: $white;
    }
  }
}

.error {
  background-color: $red;
  color: $red;
  border: 2px solid $red;

  &:disabled > * {
    color: $white;
  }

  &:hover {
    .buttonText {
      color: $red;
    }
  }
}

.secondaryText {
  color: $blue;
}

.secondaryErrorText {
  color: $red;
}

.errorText {
  color: $white;
}
