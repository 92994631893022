@use "src/common/styles/" as *;

.Orders {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
}

.loadingContainer {
  width: 100%;
}

.loadingPageTitle {
  margin-bottom: 30px;
}

.headerRow {
  margin-top: 20px;
  cursor: unset !important;

  &:hover {
    background-color: $white !important;
  }
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: $lighter-gray 1px solid;
  width: 100%;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lighter-gray;
  }
}

.orderNumberColumn {
  width: 55%;
  margin-right: 5%;
}

.dateColumn {
  display: none;
  width: 28%;
}

.customerColumn {
  display: none;
  width: 20%;
}

.totalColumn {
  display: none;
  width: 22%;
}

.statusColumn {
  width: 30%;
}

.headerText {
  padding: 0;
  margin: 0;
}

.rowText {
  padding: 0;
  margin: 0;
  font-weight: $semi-bold;
  font-size: small;
}

.rowSubtext {
  padding: 0;
  margin: 0;
  font-weight: $semi-bold;
  font-size: small;
  color: $light-gray;
  margin-top: 5px;
}

.buttonsContainer {
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.pageChangeButton {
  width: 48% !important;
}

.statusIndicator {
  width: 100%;
}

@media (width >= 875px) {
  .Orders {
    width: 100%;
  }

  .row {
    width: 95%;
  }

  .loadingContainer {
    width: 95%;
  }

  .orderNumberColumn {
    width: 10%;
  }

  .dateColumn {
    display: block;
    width: 31%;
    margin-right: 3%;
  }

  .customerColumn {
    display: block;
    width: 17%;
    margin-right: 3%;
  }

  .totalColumn {
    display: block;
    width: 14%;
  }

  .statusColumn {
    width: 22%;
  }

  .buttonsContainer {
    align-self: flex-end;
    width: 30%;
    margin-right: 5%;
  }
}
