@use "src/common/styles/" as *;

.Order {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  height: 100%;
}

.loadingPageTitle {
  margin-bottom: 30px;
}

.headerRow {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.contentContainer {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pageTitle {
  margin: 0;
  padding: 0;
}

.pageSubtitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: $light-gray;
  font-weight: $semi-bold;
  margin-bottom: 10px;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.icon {
  width: 20px;
  height: 20px;
  color: $black;
}

.menuButton {
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 30%);
  background-color: $white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lighter-gray;

    & .icon {
      color: $white;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.modalTitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 35px;
}

.refundOrderItemModalTitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 15px;
}

.modalButton {
  width: 95%;
  margin-bottom: 20px;
}

.modalLoadingContainer {
  margin-top: 35px;
}

.refundOrderItems {
  width: 95%;
  height: 225px;
  overflow-y: auto;
}

.refundOrderItemContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: $lighter-gray 1px solid;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 5px;
  box-sizing: border-box;

  &:hover {
    background-color: $lighter-gray;
  }
}

.refundOrderItemName {
  margin: 0;
  padding: 0;
  font-size: small;
  font-weight: $semi-bold;
}

.refundOrderItemQuantity {
  font-weight: $bold;
}

.refundOrderItemPrice {
  margin: 0;
  padding: 0;
  font-size: small;
  font-weight: $bold;
  color: $light-gray;
  margin-top: 5px;
}

.refundOrderItemButtonsContainer {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.refundOrderItemButton {
  width: 48%;
}

.refundOrderItemButtonText {
  font-size: small;
}

.refundOrderItemLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width >= 875px) {
  .Order {
    width: 100%;
  }

  .loadingContainer {
    width: 95%;
  }

  .headerRow {
    width: 95%;
  }

  .contentContainer {
    width: 95%;
  }

  .desktopLeft {
    width: 66%;
  }

  .desktopRight {
    width: 31%;
  }

  .pageSubtitle {
    margin-bottom: 0;
  }

  .orderDetails {
    margin-right: 25px;
  }

  .headerLeft {
    flex-direction: row;
  }
}

@media (width >= 1200px) {
  .contentContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}
