@use "src/common/styles/" as *;

.Financials {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
  margin-bottom: 50px;
}

.tabsContainer {
  display: none;
}

.tabText {
  margin-right: 45px;
  color: $black;
  cursor: pointer;
}

.tabTextSelected {
  text-decoration: underline;
  color: $blue;
}

.dropdown {
  display: block;
  width: 100%;
  margin-top: 25px;
}

.dropdownControl {
  border-radius: 10px !important;
  font-size: small !important;
  font-weight: $semi-bold !important;
  border: 1px solid $lighter-gray;
  cursor: pointer !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
}

.dropdownMenu {
  margin-top: 5px !important;
  font-size: small !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
  border-radius: 5px;
  animation: scale-up 0.1s ease-out forwards;

  > div {
    color: $black !important;
  }
}

@media (width >= 875px) {
  .Financials {
    width: 100%;
  }

  .tabsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dropdown {
    display: none;
  }
}
