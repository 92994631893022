@use "src/common/styles/" as *;

.ProfitChart {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $very-light-gray;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  height: 40%;
  padding: 15px;
  box-sizing: border-box;
  min-height: 280px;
}

.title {
  margin: 0;
  margin-bottom: 30px;
}

.loadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@media (width >= 875px) {
  .ProfitChart {
    width: 92.5%;
    padding: 25px;
  }
}
