@use "src/common/styles/" as *;

.Pricing {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.title {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.text {
  margin: 0;
  padding: 0;
  font-size: small;
  color: $light-gray;
  font-weight: $semi-bold;
}

.boldText {
  font-size: medium;
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: $extra-bold;
}

@media (width >= 1200px) {
  .Pricing {
    width: 100%;
  }
}
