@use "src/common/styles/" as *;

.RestaurantDesignContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.RestaurantDesign {
  display: flex;
  width: 95%;
  background-color: $white;
  flex-direction: column;
  align-self: center;
  margin-bottom: 30px;
}

.settingTitle {
  margin: 0;
  padding: 0;
  margin-top: 35px;
}

.settingSubtitle {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  font-weight: $semi-bold;
  color: $light-gray;
}

.announcementBarInput {
  margin-top: 15px;
  width: 100%;
}

.bottomSaveButton {
  width: 100%;
  height: 80px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  box-shadow: 0 6px 20px 10px rgb(0 0 0 / 19%) !important;
  cursor: pointer;
}

.saveText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: large;
  font-weight: $bold;
}

@media (width >= 875px) {
  .RestaurantDesign {
    width: 100%;
  }

  .announcementBarInput {
    width: 90%;
  }

  .bottomSaveButton {
    width: calc(100% - 225px);
    margin-left: -50px;
  }
}
