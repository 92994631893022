@use "src/common/styles/" as *;

.Menu {
  display: flex;
  width: 95%;
  background-color: $white;
  flex-direction: column;
}

.loading {
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.topRow {
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.publishButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%) !important;
  border-radius: 10px;
  width: 45%;
}

.publishButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  background-color: $white;
  border-right: $black 1px solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px 0;
}

.unpublishButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  background-color: $white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 0;
}

.publishButtonText {
  font-weight: $semi-bold;
  margin: 0;
  padding: 0;
}

.entityTypeContainer {
  display: none;
}

.entityText {
  margin-right: 45px;
  color: $black;
  cursor: pointer;
}

.entityTextSelected {
  text-decoration: underline;
  color: $blue;
}

.dropdown {
  display: block;
  width: 45%;
}

.dropdownControl {
  border-radius: 10px !important;
  font-size: small !important;
  font-weight: $semi-bold !important;
  border: 1px solid $lighter-gray;
  cursor: pointer !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
}

.dropdownMenu {
  margin-top: 5px !important;
  font-size: small !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
  border-radius: 5px;
  animation: scale-up 0.1s ease-out forwards;

  > div {
    color: $black !important;
  }
}

.searchContainer {
  width: 100%;
  align-self: center;
  border: $lighter-gray 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 7.5px 10px;
  box-sizing: border-box;
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.searchInput {
  border: none;
  width: calc(100% - 40px);
  outline: none;
}

.entitiesContainer {
  margin-top: 20px;
  width: 100%;
  align-self: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-bottom: $very-light-gray 1px solid;
  transition: background-color 0.2s;

  &:not(:first-child) {
    cursor: pointer;
  }

  &:not(:first-child):hover {
    background-color: $lighter-gray;
  }
}

.checkboxColumn {
  width: 10%;
}

.checkbox {
  width: 20px;
  height: 20px;
  accent-color: $blue;
  cursor: pointer;
}

.nameColumn {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statusColumn {
  width: 28%;
  margin-left: 2%;
}

.infoColumn {
  display: none;
}

.entityImage {
  width: 20%;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.rowText {
  font-weight: $semi-bold;
}

.createButton {
  width: fit-content;
  display: flex;
}

.statusPill {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: fit-content;
  padding: 7px 0;
}

.published {
  background-color: $dark-green;
}

.unpublished {
  background-color: $red;
}

.statusPillText {
  color: $white;
  font-weight: $semi-bold;
  padding: 0;
  margin: 0;
}

@media (width >= 875px) {
  .topRow {
    width: 95%;
    align-self: flex-start;
  }

  .searchContainer {
    width: 95%;
    align-self: flex-start;
  }

  .entitiesContainer {
    width: 95%;
    align-self: flex-start;
  }

  .entityImage {
    margin-right: 25px;
  }
}

@media (width >= 950px) {
  .Menu {
    width: 100%;
  }

  .loading {
    padding-right: 50px;
  }

  .entityTypeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .publishButtonsContainer {
    width: 300px;
  }

  .dropdown {
    display: none;
  }

  .checkboxColumn {
    width: 6%;
  }

  .nameColumn {
    width: 32%;
  }

  .statusColumn {
    width: 26%;
  }

  .infoColumn {
    display: block;
    width: 38%;
  }

  .entityImage {
    width: 20%;
  }

  .statusPill {
    width: 60%;
  }
}
