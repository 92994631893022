@use "src/common/styles/" as *;

.StatCard {
  display: flex;
  flex-direction: column;
  background-color: $very-light-gray;
  border-radius: 5px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  min-height: 135px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135px;
}

.title {
  margin: 0;
  font-weight: $bold;
  font-size: medium;
}

.stat {
  margin: 0;
  font-weight: $bold;
  font-size: x-large;
}

@media (width >= 875px) {
  .StatCard {
    padding: 25px;
  }
}
