@use "src/common/styles/" as *;

.CreateInvoiceModal {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 30px;
  height: 100%;
  overflow-y: auto;
}

.title {
  margin-top: 10px;
  margin-bottom: 20px;
}


.lineItemsContainer {
  width: 100%;
}

.lineItemsTitle {
  margin-bottom: 30px;
}

.lineItemsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.eachLineItem {
  flex: 1;
}

.titleInput{
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  font-family: $default-font-family;
}

.amountInputContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  margin-left: auto; 
}

.moneySymbol {
  margin-right: 5px;
  font-size: 16px;
  color: $light-gray;
}

.amountInput {
  width: 75px; 
  box-sizing: border-box;
  font-family: $default-font-family;
  text-align: right;
}

.errorText {
  color: $red;
  display: flex;
  justify-content: center; 
  align-items: center;
  font-size: medium;
  margin-top: 5px;
  text-align: center; 
  width: 100%; 
}

.addLineButton{
  width:fit-content;
  height: 25px;
}

.removeLineButton{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.removeLineText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: x-large;
  font-weight: $bold;
  padding-bottom: 3px;
}

.totalsContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.totalsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px; 
}

.totalsText {
  font-size: medium;
  font-weight: $bold;
  margin: 0;  
}

.subtotalsText {
  font-size: medium;
  color: $light-gray;
  margin: 0;  
}

.requiredTipRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 30px;
  margin-top: 10px; 
}

.buttonContainer {
  margin-top: auto;
  margin-bottom: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;;
  align-items: center;
  align-self: center;
}

.createInvoiceButton {
  width: 100%;
  margin-top: 5px;
}


.cancelButton {
  width: 100%;
}

.tipCheckboxContainer{
  display: flex;
  align-items: center;
}

.tipCheckbox {
  margin-left: 0;
  margin-right: 10px;
  padding: 0;
}


@media (width >= 875px) {
  .buttonContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .createInvoiceButton {
    width: 60%;
  }
  
  
  .cancelButton {
    width: 35%;
  }

  .requestDetailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .requestDetailsContainer > * {
    flex: 1; 
    min-width: 0; 
  }
}
