@use "src/common/styles/" as *;

.statusIndicatorText {
  padding: 0;
  margin: 0;
  font-weight: $semi-bold;
  font-size: small;
  color: $white;
  text-align: center;
}

.statusIndicator {
  width: fit-content;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  padding: 5px 15px;
  box-sizing: border-box;
}

.redIndicator {
  background-color: $red;
}

.greenIndicator {
  background-color: $dark-green;
}
