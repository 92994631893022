@use "src/common/styles/" as *;

.SettingContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.HoursOfOperation {
  display: flex;
  width: 95%;
  background-color: $white;
  flex-direction: column;
  align-self: center;
  margin-bottom: 30px;
}

.selectDropdown {
  border-radius: 10px !important;
  font-size: small !important;
  font-weight: $semi-bold !important;
  cursor: pointer !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%) !important;
  margin-top: 20px;
  width: 100%;
  border: none;
  outline: none;
}

.updateHoursModeButton {
  margin-top: 20px;
  margin-bottom: 20px;
  width: fit-content;
  padding: 5px 10px;
}

.updatingModeLoadingIndicator {
  margin-top: 20px;
  align-self: center;
}

.indicatorRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: $dark-green;
}

.redIndicator {
  background-color: $red;
}

.indicatorText {
  margin: 0;
  padding: 0;
  color: $dark-green;
}

.redIndicatorText {
  color: $red;
}

.dayContainer {
  margin-top: 20px;
}

.dayRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  border-bottom: $lighter-gray 1px solid;
  padding-bottom: 20px;
}

.timesContainer {
  width: 100%;
}

.dayName {
  font-weight: $bold;
  margin: 0;
  padding: 0;
  font-size: medium;
}

.switch {
  transform: scale(0.8);
}

.switchRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switchText {
  margin-left: 10px;
}

.timesRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.eachTime {
  width: 48%;
}

.eachTimeWithRemoveButton {
  width: 44%;
}

.timeTextContainer {
  cursor: pointer;
  width: 100%;
  border: $lighter-gray 1px solid;
  border-radius: 10px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-family: $default-font-family;
}

.opensClosesAtText {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  margin-bottom: 10px;
}

.addTimeButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addTimeText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: x-large;
  font-weight: $bold;
}

.removeTimeButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.removeTimeText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: x-large;
  font-weight: $bold;
  margin-top: -3px;
}

.bottomSaveButton {
  width: 100vw;
  height: 80px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  box-shadow: 0 6px 20px 10px rgb(0 0 0 / 19%) !important;
  cursor: pointer;
}

.saveText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: large;
  font-weight: $bold;
}

@media (width >= 875px) {
  .HoursOfOperation {
    width: 100%;
  }

  .selectDropdown {
    width: 60%;
  }

  .updatingModeLoadingIndicator {
    align-self: flex-start;
  }

  .dayRow {
    width: 70%;
    flex-direction: row;
  }

  .timesContainer {
    width: 60%;
  }

  .bottomSaveButton {
    width: calc(100vw - 175px);
    margin-left: -50px;
  }
}

@media (width >= 1000px) {
  .selectDropdown {
    width: 40%;
  }

  .dayRow {
    width: 60%;
  }
}
