@use "src/common/styles/" as *;

.Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 10%);
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}

.restaurantLogoContainer {
  display: none;
}

.restaurantLogo {
  width: 54px;
  height: 54px;
  border-radius: 27px;
}

.drawerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 20px;
}

.drawerItem {
  text-decoration: none;
  color: $black;
  font-family: $default-font-family;
  padding: 10px 10%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    margin: 0;
  }
}

.bars {
  cursor: pointer;
  color: $blue;
}

.drawerItemActive {
  color: $blue;
  background-color: #2266a633;
}

.drawerLogo {
  width: 30%;
  align-self: center;
  margin-bottom: 30px;
  border-radius: 10px;
}

.restaurantName {
  display: none;
}

.platterLogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.platterLogo {
  height: 60px;
  width: 60px;
}

.platterName {
  display: none;
}

.signOutDrawerItem {
  position: absolute;
  bottom: 0;
}

.profileDropdownContainer {
  position: absolute;
  bottom: 0;
}

@media (width >= 875px) {
  .restaurantLogoContainer {
    display: flex;
    background-color: $lighter-gray;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    box-shadow: 0 6px 20px 0 rgb(0 0 0 / 10%);
    justify-content: center;
    align-items: center;
  }

  .platterName {
    display: block;
    color: $blue;
    margin-left: 10px;
  }

  .mobileMenuIcon {
    display: none;
  }

  .mobileSpacer {
    display: none;
  }

  .restaurantName {
    display: block;
  }
}
