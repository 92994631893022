@use "src/common/styles/" as *;

.MonthlyStatements {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerRow {
  margin-top: 35px;
  cursor: unset !important;

  &:hover {
    background-color: $white !important;
  }
}

.iconContainer {
  background-color: $blue;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: background-color 0.3s;
}

.downloadIcon {
  width: 15px;
  height: 15px;
  color: $white;
  transition: background-color 0.3s;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 5px;
  border-bottom: $lighter-gray 1px solid;
  box-sizing: border-box;
  width: 100%;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lighter-gray;

    .iconContainer {
      background-color: $white;
    }

    .downloadIcon {
      color: $blue;
    }
  }
}

.monthColumn {
  width: 60%;
}

.downloadColumn {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.headerText {
  padding: 0;
  margin: 0;
}

.rowText {
  padding: 0;
  margin: 0;
  font-weight: $regular;
  font-size: medium;
}

@media (width >= 875px) {
  .MonthlyStatements {
    width: 90%;
  }

  .emptyState {
    width: 30%;
  }

  .row {
    align-self: center;
    width: 65%;
  }
}
