@use "src/common/styles/" as *;

.PhotoChooser {
  margin-top: 10px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transition: opacity 0.3s;
}

.isDragging {
  opacity: 0.5;
}

.noImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  width: 125px;
  border-radius: 10px;
  background-color: $light-gray;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.photosSelectorImage {
  height: 125px;
  width: 125px;
  border-radius: 10px;
  transition: opacity 0.3s;
  cursor: pointer;
  object-fit: contain;

  &:hover {
    opacity: 0.7;
  }
}

.editPhotoText {
  text-decoration: underline;
  color: $blue;
  font-size: small;
  font-weight: $bold;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.removePhotoText {
  text-decoration: underline;
  color: $red;
  font-size: small;
  font-weight: $bold;
  margin: 0;
  padding: 0;
  word-wrap: none;
  margin-left: 10px;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
