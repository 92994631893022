@use "src/common/styles/" as *;

.ItemList {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 22%);
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.title {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

@media (width >= 1200px) {
  .ItemList {
    width: 100%;
  }
}
