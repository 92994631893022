@use "src/common/styles/" as *;

.SettingsItem {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%);
  border-radius: 10px;
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  cursor: pointer;
  margin-top: 25px;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.title {
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.subtitle {
  margin: 0;
  padding: 0;
  font-weight: $regular;
  color: $light-gray;
}

@media (width >= 875px) {
  .SettingsItem {
    width: 48%;
    margin-right: 2%;
  }
}

@media (width >= 1300px) {
  .SettingsItem {
    width: 31%;
    margin-right: 2%;
  }
}
