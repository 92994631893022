@use "src/common/styles/" as *;

.SelectableCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.selected {
  background-color: $blue;
  box-sizing: border-box;
}
