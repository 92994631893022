@use "src/common/styles/" as *;

.ItemContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.Item {
  display: flex;
  width: 90%;
  background-color: $white;
  flex-direction: column;
  align-self: center;
  margin-bottom: 30px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  height: 100%;
}

.headerRow {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.pageTitle {
  margin: 0;
  padding: 0;
}

.menuButton {
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 30%);
  background-color: $white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lighter-gray;

    & .icon {
      color: $white;
    }
  }
}

.statusPill {
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: fit-content;
  padding: 4px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.published {
  background-color: $dark-green;
}

.unpublished {
  background-color: $red;
}

.statusPillText {
  color: $white;
  font-weight: $bold;
  padding: 0;
  margin: 0;
  font-size: small;
}

.bottomSaveButton {
  width: 100%;
  height: 80px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  box-shadow: 0 6px 20px 10px rgb(0 0 0 / 19%) !important;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.saveText {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: large;
  font-weight: $bold;
}

.nameInput {
  width: 90%;
  align-self: center;
}

.descriptionInput {
  width: 90%;
  align-self: center;
  margin-top: 20px;
}

.priceInput {
  width: 50%;
  align-self: flex-start;
  margin-top: 20px;
  margin-left: 5%;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.modalTitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 35px;
}

.modalButton {
  width: 95%;
  margin-bottom: 20px;
}

.modalLoadingContainer {
  margin-top: 35px;
}

@media (width >= 875px) {
  .Item {
    width: 100%;
  }

  .loadingContainer {
    width: 95%;
  }

  .headerRow {
    width: 95%;
  }

  .bottomSaveButton {
    width: calc(100% - 225px);
    margin-left: -50px;
  }

  .nameInput {
    width: 50%;
    align-self: flex-start;
  }
  
  .descriptionInput {
    width: 50%;
    align-self: flex-start;
  }
  
  .priceInput {
    width: 15%;
    margin-left: 0;
  }
}
