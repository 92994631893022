@use "src/common/styles/" as *;

.Payouts {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
}

.loadingContainer {
  margin-top: 25px;
  width: 100%;
}

.connectYourStripeMessage {
  align-self: center;
  margin-top: 20vh;
  text-align: center;
}

.connectLink {
  color: $blue;
}

.headerRow {
  margin-top: 20px;
  cursor: unset !important;

  &:hover {
    background-color: $white !important;
  }
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: $lighter-gray 1px solid;
  width: 100%;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lighter-gray;
  }
}

.amountColumn {
  width: 30%;
  margin-right: 2%;
}

.statusColumn {
  width: 30%;
  margin-right: 2%;
}

.initiatedColumn {
  display: none;
}

.arrivalColumn {
  width: 35%;
}

.buttonsContainer {
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.pageChangeButton {
  width: 48% !important;
}

.headerText {
  padding: 0;
  margin: 0;
}

.futurePayouts {
  padding: 0;
  margin: 0;
  font-weight: $regular;
  font-size: medium;
}

.futurePayoutsDollarAmount {
  font-weight: $bold;
  margin: 0;
  padding: 0;
  margin-top: 7.5px;
  font-size: larger;
}

.rowText {
  padding: 0;
  margin: 0;
  font-weight: $semi-bold;
  font-size: small;
}

.statusIndicator {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  padding: 2.5px 10px;
}

.statusText {
  color: $white;
  font-weight: $bold;
  text-wrap: nowrap;
  text-align: center;
}

@media (width >= 875px) {
  .Payouts {
    margin-top: 0;
  }

  .loadingContainer {
    width: 95%;
  }

  .row {
    width: 95%;
  }

  .amountColumn {
    width: 21%;
  }

  .statusColumn {
    display: block;
    width: 20%;
    margin-right: 3%;
  }

  .initiatedColumn {
    display: block;
    width: 30%;
    margin-right: 5%;
  }

  .arrivalColumn {
    display: block;
    width: 21%;
  }

  .buttonsContainer {
    align-self: flex-end;
    width: 30%;
    margin-right: 5%;
  }
}
